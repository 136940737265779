import React from 'react';
import Downshift, {
  ControllerStateAndHelpers,
  DownshiftState,
  StateChangeOptions,
} from 'downshift';
import { NormalizedDropdownItemType } from './useNormalizedItems';
import classNames from 'classnames';

const DownshiftContext = React.createContext<ControllerStateAndHelpers<
  NormalizedDropdownItemType
> | null>(null);

export type DownshiftProviderProps = {
  onChange?: (
    selectedItem: NormalizedDropdownItemType,
    stateAndHelpers: ControllerStateAndHelpers<NormalizedDropdownItemType>,
  ) => void;
  onInputValueChange?: (value: string) => void;
  inputValue?: string;
  initialSelectedItem?: NormalizedDropdownItemType;
  highlightFirstItemOnOpen?: boolean;
  className?: string;
  style?: React.CSSProperties;
  [key: string]: any;
};
export const DownshiftProvider: React.FC<DownshiftProviderProps> = ({
  children,
  onChange = () => {},
  onInputValueChange = () => {},
  highlightFirstItemOnOpen = false,
  className,
  style,
  ...rest
}) => {
  const handleStateChange = (
    changes: any,
    stateAndHelpers: ControllerStateAndHelpers<NormalizedDropdownItemType>,
  ) => {
    if (
      changes.type ===
      Downshift.stateChangeTypes.controlledPropUpdatedSelectedItem
    ) {
      return;
    }
    if ('selectedItem' in changes) {
      onChange(changes.selectedItem, stateAndHelpers);
    } else if ('inputValue' in changes) {
      onInputValueChange(changes.inputValue);
    }
  };

  const stateReducer = (
    _: DownshiftState<NormalizedDropdownItemType>,
    changes: StateChangeOptions<NormalizedDropdownItemType>,
  ): Partial<StateChangeOptions<NormalizedDropdownItemType>> => {
    if (highlightFirstItemOnOpen) {
      const wasJustOpened = 'isOpen' in changes && changes.isOpen;
      if (wasJustOpened) {
        return { ...changes, highlightedIndex: 0 };
      }
    }
    return changes;
  };

  return (
    <Downshift
      itemToString={item => (item ? item.label : '')}
      onStateChange={handleStateChange}
      stateReducer={stateReducer}
      {...rest}
    >
      {args => (
        <div className={classNames('eds-input-group', className)} style={style}>
          {/* This div is required by Downshift */}
          <DownshiftContext.Provider value={args}>
            {children}
          </DownshiftContext.Provider>
        </div>
      )}
    </Downshift>
  );
};

export const useDownshift = () => {
  const context = React.useContext(DownshiftContext);
  if (!context) {
    throw new Error('You need to wrap your component in a DownshiftProvider');
  }
  return context;
};
